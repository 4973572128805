import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./Categories.css";

export default function Categories({ catID }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://meta-detector.com/v1/api/groups")
      .then((res) => res.json())
      .then((resutl) => {
        setCategories(resutl.data);
      });
  }, []);

  return (
    <div className="filtres" id="aside">
      <form id="formFiltre" name="formFiltre" method="post">
        <input type="hidden" name="liste" id="liste" value="32" />
        <input type="hidden" name="la_page" id="la_page" value="1" />
        <input type="hidden" name="tri" id="tri" value="" />
        <div className="filter">
          <ul className="links">
            <li>CATEGORIES</li>
            {categories.map((categoray) => (
              <li key={categoray.id} className={`${Number(catID) === categoray.id && "select"}`}>
                <Link to={`/products/${categoray.id}`}>{categoray.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <a
          href="javascript:;"
          className="btn-closefilter"
          aria-label="Fermer les filtres"
        ></a>
        <a href="javascript:;" className="bouton btn-results">
          See the results
        </a>
      </form>
    </div>
  );
}
