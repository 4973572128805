import React, { useEffect, useState } from "react";

import "./MainVisuel.css";
import apiRequests from "../../services/config";
import { Link } from "react-router-dom";

const MainVisuel = ({ slideOne }) => {
  const [slideMianHeader, setSlideMainHeader] = useState({});

  useEffect(() => {
    setSlideMainHeader({
      title: slideOne?.title,
      description: slideOne?.description,
      image: slideOne?.image1,
      link: slideOne.link,
    });
  }, []);
  return (
    <div className="wrapper-main-visuel left">
      <div className="caption">
        <span className="line"></span>
        <h1
          className="w-25 element-paroller text-start active"
          style={{
            transform: "translateY(-14px)",
            transition: "transform 0s linear 0s",
            willChange: "transform",
          }}
        >
          {typeof slideMianHeader.link === "string" ? (
            <Link to={slideMianHeader.link}>{slideMianHeader.title}</Link>
          ) : (
            <span>{slideMianHeader.title}</span>
          )}
        </h1>
      </div>
      <div
        className="wrapper-main-img"
        style={{
          background: `url(${window.location.origin}${slideMianHeader.image})`,
        }}
      ></div>

      <div className="update">
        <a href="javascript:;" className="icon">
          <span>Update </span>
        </a>
      </div>
      <div className="w-50 update-content">
        <p>
          {slideMianHeader.description}
          <br />
          <a href="#">
            It is free, easy to install and can be done in just a few minutes
          </a>
        </p>{" "}
      </div>
    </div>
  );
};

export default MainVisuel;
