import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import { RouterProvider } from "react-router-dom";

import routes from "./router";
import ScrollTop from "./components/scrollTop/ScrollTop";

let router = routes;

export default function App() {
  return (
    <>
      <ScrollTop />
      <ScrollToTop top={0} />
      <RouterProvider router={router} />
    </>
  );
}
