import React, { useEffect, useState } from "react";

export default function Navbar() {
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    const adminToken = localStorage.getItem("token");

    if (!adminToken) {
      setIsLogin(false);
    }
  }, []);

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark py-4 mb-4">
      {/* <!-- Sidebar Toggle--> */}
      {isLogin && (
        <button
          className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
          id="sidebarToggle"
          href="#!"
        >
          <i className="fas fa-bars"></i>
        </button>
      )}
    </nav>
  );
}
