import React, { useEffect, useState } from "react";
import data from "./../../data.json";

import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [allMenu, setAllMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState(false);

  const openMenuMobile = () => {
    setActiveMenu(!activeMenu);

    if (!activeMenu) {
      document
        .querySelectorAll(".layer-2")
        .forEach((item) => item.classList.remove("active"));

      document
        .querySelectorAll(".layer-3")
        .forEach((item) => item.classList.remove("active"));
    }
  };

  const openSubMenu = (event) => {
    if (event.target.classList.value === "link link-layer link-layer-2") {
      event.target.nextElementSibling.classList.add("active");
    }

    if (event.target.classList.value === "link link-layer link-layer-3") {
      event.target.nextElementSibling.classList.add("active");
      console.log(event.target.nextElementSibling.classList);
    }
  };

  const openSubSubMenu = (event) => {
    document
      .querySelectorAll(".layer-2")
      .forEach((item) => item.classList.remove("active"));
  };

  const clickMenu = (event) => {
    document
      .querySelectorAll(".layer-3")
      .forEach(
        (item) =>
          item.classList.value.includes("active") &&
          item.classList.remove("active")
      );

    setActiveMenu(false);
  };

  useEffect(() => {
    fetch("https://meta-detector.com/v1/api/groups")
      .then((res) => res.json())
      .then((resutl) => {
        setAllMenu(resutl.data);
      });
  }, []);

  return (
    <>
      <div
        onClick={openMenuMobile}
        className={`menu-toggle btn-opennav ${activeMenu && "open"}`}
      >
        <div id="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div id="cross">
          <span></span>
          <span></span>
        </div>
      </div>
      <nav className={`${activeMenu && "active"}`}>
        <i className={`menu-background top ${activeMenu && "active"}`}></i>
        <i className={`menu-background middle ${activeMenu && "active"}`}></i>
        <i className={`menu-background bottom ${activeMenu && "active"}`}></i>
        <div>
          <ul className="level1">
            {/* Detectors Menus */}
            <li className="sub-level" onClick={openSubMenu}>
              <Link to="javascript:;" className="link link-layer link-layer-2">
                Detectors
              </Link>
              <div className={`layer layer-2`}>
                <div className="container">
                  <ul>
                    <li>
                      <a
                        href=""
                        className="link link-layer link-layer-3 link-all"
                      >
                        <span>See everything</span>
                      </a>
                    </li>
                    {allMenu.map((menu) => (
                      <>
                        {menu.type === 1 ? (
                          <li key={menu.id} onClick={openSubSubMenu}>
                            <Link
                              to="javascript:;"
                              className="link link-layer link-layer-3"
                            >
                              {menu.name}
                            </Link>
                            <div className={"layer layer-3"}>
                              <ul>
                                <li>
                                  <a
                                    href=""
                                    className="link link-layer link-layer-3 link-all"
                                  >
                                    <span>See everything</span>
                                  </a>
                                </li>
                                $
                                {menu.product.map((subMenuItem, index) => (
                                  <li key={subMenuItem.id}>
                                    <Link
                                      to={`/product/${subMenuItem.title}`}
                                      onClick={clickMenu}
                                      className="link link-layer link-layer-3"
                                    >
                                      <span className="menu-link">
                                        {subMenuItem.title}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </li>

            {/* Accessories Muns */}
            <li className="sub-level" onClick={openSubMenu}>
              <Link to="javascript:;" className="link link-layer link-layer-2">
                Accessories
              </Link>
              <div className={`layer layer-2`}>
                <div className="container">
                  <ul>
                    <li>
                      <a
                        href=""
                        className="link link-layer link-layer-3 link-all"
                      >
                        <span>See everything</span>
                      </a>
                    </li>

                    <li onClick={openSubSubMenu}>
                      <Link
                        to="javascript:;"
                        className="link link-layer link-layer-3"
                      >
                        XP accessories
                      </Link>
                      <div className={"layer layer-3"}>
                        <ul>
                          <li>
                            <a
                              href=""
                              className="link link-layer link-layer-3 link-all"
                            >
                              <span>See everything</span>
                            </a>
                          </li>
                          {allMenu.map((menu) => (
                            <>
                              {menu.type === 2 ? (
                                <li key={menu.id}>
                                  <Link
                                    to={`/products/${menu.id}`}
                                    onClick={clickMenu}
                                    className="link link-layer link-layer-3"
                                  >
                                    <span className="menu-link">
                                      {menu.name}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li onClick={openSubSubMenu}>
                      <Link
                        to="javascript:;"
                        className="link link-layer link-layer-3"
                      >
                        Applications
                      </Link>
                      <div className={"layer layer-3"}>
                        <ul>
                          <li>
                            <a
                              href=""
                              className="link link-layer link-layer-3 link-all"
                            >
                              <span>See everything</span>
                            </a>
                          </li>
                          <li>
                            <Link
                              to={`/download-app`}
                              onClick={clickMenu}
                              className="link link-layer link-layer-3"
                            >
                              <span className="menu-link">
                                Download Application
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/check-serial`}
                              onClick={clickMenu}
                              className="link link-layer link-layer-3"
                            >
                              <span className="menu-link">Check Serial</span>
                            </Link>
                          </li>
                          {/* ))
                                : ""}
                            </>
                          ))} */}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            {/* Information Muns */}
            <li className="sub-level" onClick={openSubMenu}>
              <Link to="javascript:;" className="link link-layer link-layer-2">
                Information
              </Link>
              <div className={`layer layer-2`}>
                <div className="container">
                  <ul>
                    <li>
                      <a
                        href=""
                        className="link link-layer link-layer-3 link-all"
                      >
                        <span>See everything</span>
                      </a>
                    </li>
                    <li onClick={openSubSubMenu}>
                      <Link
                        to="javascript:;"
                        className="link link-layer link-layer-3"
                      >
                        Product information
                      </Link>
                      <div className="layer layer-3 ">
                        <ul>
                          <li>
                            <a
                              href=""
                              className="link link-layer link-layer-3 link-all"
                            >
                              <span>See everything</span>
                            </a>
                          </li>
                          <li>
                            <Link
                              to={`/faq`}
                              onClick={clickMenu}
                              className="link link-layer link-layer-3"
                            >
                              <span className="menu-link">FAQ</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
