import React from "react";

import "./Rea.css";

export default function Rea() {
  return (
    <div className="wrapper-rea">
      <div className="container">
        <ul>
          <li className="wrapperClic">
            <h4>Where to buy? </h4>
            <p>
              Find all our products at our authorized resellers and distributors
              in more than 50 countries
            </p>
            <a href="https://www.xpmetaldetectors.com/en/implantations.php">
              <span className="label-name">Go to the reseller page </span>
            </a>
          </li>
          <li className="wrapperClic">
            <h4>Newsletter </h4>
            <p>Subscribe and receive all our news regularly by email </p>
            <a href="https://www.xpmetaldetectors.com/en/newsletter/inscription.php">
              <span className="label-name">Subscribe to the newsletter </span>
            </a>
          </li>
          <li className="wrapperClic">
            <h4>XP Warranty </h4>
            <p>
              In order to provide you the best quality of service, we guarantee
              all of our metal detectors for 5 years{" "}
            </p>
            <a href="https://www.xpmetaldetectors.com/en/page/warranty.php">
              <span className="label-name">Go to the XP warranty page </span>
            </a>
          </li>
          <li className="wrapperClic">
            <h4>Update </h4>
            <p>
              We regularly update the system of our detectors in order to
              continuously improve their performance{" "}
            </p>
            <a href="https://www.xpmetaldetectors.com/en/informations/updates.php">
              <span className="label-name">Go to the update page </span>
            </a>
          </li>{" "}
        </ul>
      </div>
    </div>
  );
}
