import { createBrowserRouter } from "react-router-dom";

import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import SpesiaalProduct from "./pages/spesiaalProduct/SpesiaalProduct";
import FAQ from "./pages/faq/FAQ";
import CheckSerial from "./pages/checkSerial/CheckSerial";

// Admin Panel Address
import PAdminIndex from "./pages/adminPanel/AdminHome";
import { Login } from "./pages/adminPanel/login/Login";
import Dashboard from "./pages/adminPanel/dashboard/Dashboard"
import Menu from "./pages/adminPanel/menu/Menu";
import ProductAdmin from "./pages/adminPanel/product/Product";
import Faq from "./pages/adminPanel/faq/Faq";
import FaqGroup from "./pages/adminPanel/faq/FaqGroup";
import DownloadApp from "./pages/downloadApp/DownloadApp";
import Serials from "./pages/adminPanel/serials/Serials";
import Settings from "./pages/adminPanel/settings/Settings";
import UserManuals from "./pages/adminPanel/userManuals/UserManuals";
import WhereToBuy from "./pages/whereToBuy/WhereToBuy";
import ContactUs from "./pages/contactUs/ContactUs";
import UserManualsUi from "./pages/userManualsUi/UserManualsUi";

const routes = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/products/:cat_id", element: <Product /> },
    { path: "/product/:productName", element: <SpesiaalProduct /> },
    { path: "/faq", element: <FAQ /> },
    { path: "/faq/:faq_name", element: <FAQ /> },
    { path: "/download-app", element: <DownloadApp /> },
    { path: "/check-serial", element: <CheckSerial /> },
    { path: "/where-to-buy", element: <WhereToBuy /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/user-manuals", element: <UserManualsUi /> },

    // Admin Link Address
    {
        path: "/p-admin/*",
        element: <PAdminIndex />,
        children: [
            { path: "", element: <Dashboard /> },
            { path: "group", element: <Menu /> },
            { path: "product", element: <ProductAdmin /> },
            { path: "serials", element: <Serials /> },
            { path: "faq", element: <Faq /> },
            { path: "faq-group", element: <FaqGroup /> },
            { path: "user-manual", element: <UserManuals /> },
            { path: "settings", element: <Settings /> },
            { path: "login", element: <Login /> },
        ],
    },
])

export default routes