import React from "react";

import "./Topbar.css";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <div className="header-top">
      <div className="container-fluid">
        <ul className="d-none d-sm-inline-block links-top">
          <li>
            <Link to={"/where-to-buy"}>Where to buy</Link>
          </li>
          <li>
            <Link to={"/contact-us"}>Context</Link>
          </li>
          <li>
            <Link to={"/user-manuals"}>User Manuals</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Topbar;
