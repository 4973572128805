import React, { useEffect, useState } from "react";
import Header from "./../../components/header/Header";
import Footer from "./../../components/footer/Footer";
import Categories from "../../components/categories/Categories";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import "./Product.css";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";

export default function Product() {
  const { cat_id } = useParams();
  const [products, setProducts] = useState([]);
  const [countProduct, setCountProduct] = useState(0);
  const [isList, setIsList] = useState(false);

  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    fetch("https://meta-detector.com/v1/api/products")
      .then((res) => res.json())
      .then((resutl) => {
        setProducts(resutl.data);
        setCountProduct(0);
        resutl.data.forEach((product) => {
          if (product.group_id === Number(cat_id)) {
            setCountProduct((prevCount) => prevCount + 1);
          }
        });

        setIsLoader(false);
      });
  }, [cat_id]);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Header />
      <main id="content" className={"product-wrapper"}>
        <input type="hidden" name="nbProduits" id="nbProduits" value="1" />
        <input type="hidden" name="nextPage" id="nextPage" value="2" />
        <input
          type="hidden"
          name="from_documentation"
          id="from_documentation"
          value=""
        />

        <Breadcrumb links={[{ id: 1, title: "Accessories" }]} />

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-4">
              <Categories catID={cat_id} />
            </div>
            <div className="col-12 col-lg-8 col-xl-8" id="liste-wrapper">
              <div className="wrapper-controls">
                <div className="row">
                  <div className="col-6 col-md-4">
                    <p className="results">{countProduct} Result </p>
                  </div>
                  <div className="col-6 col-md-8">
                    <div className="wrapper-view">
                      <a
                        href="javascript:;"
                        className="btn-filter"
                        aria-label="Filtrer"
                      ></a>
                      <a
                        href="javascript:;"
                        className={`btn-viewgrid view-grid ${
                          !isList && "active"
                        }`}
                        aria-label="Vue grille"
                        onClick={() => setIsList(false)}
                      ></a>
                      <a
                        href="javascript:;"
                        className={`btn-viewlist view-list ${
                          isList && "active"
                        }`}
                        aria-label="Vue liste"
                        onClick={() => setIsList(true)}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-gap-4 liste" id="listProduct">
                {products.map((product) => (
                  <>
                    {product.group_id == Number(cat_id) ? (
                      <div
                        key={product.id}
                        className={`${
                          isList ? "col-12" : "col-6 col-md-4 col-lg-6 col-xl-4"
                        } item wrapper-product`}
                      >
                        <div
                          className={`encart-product wrapperClic ${
                            isList
                              ? "encart-product-list"
                              : "encart-product-grid"
                          }`}
                        >
                          <Link to={`/product/${product.title}`} target="_self">
                            <div className="container-img">
                              <img
                                src={`${window.location.origin}${product.image.url}`}
                                alt="GO TERRAIN Application"
                                className="img-fluid"
                                style={{ display: "inline" }}
                              />
                            </div>
                          </Link>
                          <div className="container-caption">
                            <div className="caption">
                              <div className="row">
                                <div className="col-12">
                                  <p className="title">
                                    <Link
                                      to={`/product/${product.title}`}
                                      target="_self"
                                    >
                                      {product.title}
                                    </Link>
                                  </p>
                                  <div
                                    className="desc"
                                    dangerouslySetInnerHTML={{
                                      __html: product.description.slice(0, 40),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ))}
                <div
                  id="show-more"
                  className="show-more"
                  arial-label="Voir plus d'accessoires"
                  style={{ display: "none" }}
                >
                  <div className="loader-cycle-disks"></div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
