import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../components/adminPanel/navbar/Navbar";
import Sidebar from "../../components/adminPanel/sidebar/Sidebar";
import Footer from "../../components/adminPanel/footer/Footer";

import "./index.css";
function AdminHome() {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("token");

    if (!adminToken) {
      navigate("/p-admin/login");
      setIsLogin(false);
    }
  }, []);

  return (
    <div className="admin-panel">
      <Navbar />
      <div id="layoutSidenav">
        {/* {isLogin && <Sidebar />} */}
        <Sidebar />

        <div id="layoutSidenav_content">
          <main>
            <div className="container">
              <Outlet />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
