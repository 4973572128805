import React from "react";

import "./Breadcrumb.css";
import { Link } from "react-router-dom";

export default function Breadcrumb({ links }) {
  return (
    <ol
      className="breadcrumb"
      itemScope="itemScope"
      itemType="http://schema.org/BreadcrumbList"
    >
      <li itemScope="itemListElement" itemType="http://schema.org/ListItem">
        <Link to={window.location.origin} itemScope="item">
          <span itemScope="name">Home page</span>
        </Link>
      </li>
      {links.map((link) => (
        <li
          key={link.id}
          itemScope="itemListElement"
          itemType="http://schema.org/ListItem"
        >
          {link?.link ? (
            <Link to={`${window.location.origin}${link.link}`} itemScope="item">
              <span itemScope="name">{link.title}&nbsp;</span>
            </Link>
          ) : (
            <span itemScope="name">{link.title}&nbsp;</span>
          )}
        </li>
      ))}
    </ol>
  );
}
