import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import MainVisuel from "../../components/mainVisuel/MainVisuel";
import Test from "../../components/test/Test";
import Terrain from "../../components/terrain/Terrain";
import Rea from "../../components/rea/Rea";
import Footer from "../../components/footer/Footer";
import apiRequests from "../../services/config";
import Loader from "../../components/loader/Loader";

import "./Home.css";

const Home = () => {
  const [brand, setBrand] = useState("");
  const [imageForth, setImageForth] = useState("img/mainvisuel/big.jpg");
  const [firstSlider, setFirstSlider] = useState({});
  const [secondSlider, setSecondSlider] = useState({});
  const [thridSlider, setThridSlider] = useState({});
  const [appData, setAppData] = useState({});

  const [whereToBuy, setWhereToBuy] = useState("");
  const [telegramAccont, setTelegramAccont] = useState("");
  const [instagramAccont, setInstagramAccont] = useState("");
  const [whatsappAccont, setWhatsappAccont] = useState("");
  const [contactUs, setContactUs] = useState("");

  const [isLoader, setIsLoader] = useState(true);

  const getSettingsPage = async () => {
    await apiRequests("/get-setting").then((res) => {
      const sliderData = JSON.parse(res.data.data.sliders);
      const appSliders = JSON.parse(res.data.data.app_sliders);

      document.title = res.data.data.brand;
      setAppData({
        appTitle: res.data.data.app_title,
        appDescription: res.data.data.app_description,
        googlePlay: res.data.data.googleplay_link,
        appStore: res.data.data.appstore_link,
        appLink: res.data.data.apk_path,
        appSiliders: appSliders,
      });
      setWhereToBuy(res.data.data.where_to_buy);
      setTelegramAccont(res.data.data.telegram);
      setInstagramAccont(res.data.data.instagram);
      setWhatsappAccont(res.data.data.whatsapp);
      setContactUs(res.data.data.contact_us);

      setFirstSlider(sliderData[0]);
      setSecondSlider(sliderData[1]);
      setThridSlider(sliderData[2]);

      setIsLoader(false);
    });
  };

  useEffect(() => {
    getSettingsPage();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Header />
      <MainVisuel slideOne={firstSlider} />
      <main>
        <Test slideTow={secondSlider} slideThree={thridSlider} />
        <Terrain appDatas={appData} />
        <Rea />
      </main>
      <Footer />
    </>
  );
};

export default Home;
