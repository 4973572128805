import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/Loader";
import Editor from "../../../components/adminPanel/form/Editor";
import apiRequests from "../../../services/config";
import swal from "sweetalert";

export default function UserManuals() {
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [userManualsData, setUserManualsData] = useState([]);
  const [pdfID, setPdfID] = useState(0);
  const [pdfTitle, setPdfTitle] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [pdfDescription, setPdfDescription] = useState("");

  const getUserManualsData = async () => {
    await apiRequests("/user-manuals").then((res) => {
      setUserManualsData(res.data.data);
      setIsLoader(false);
    });
  };

  const submitUserManualsDetails = async () => {
    setIsLoader(true);

    const newUserManual = {
      title: pdfTitle,
      description: pdfDescription,
      file: pdfFile,
    };

    await apiRequests
      .post("/user-manuals/create", newUserManual)
      .then((res) => {
        setIsLoader(false);
        swal({
          title: "اطلاعات با موفقیت ثبت شد.",
          icon: "success",
          buttons: "حله",
        }).then((result) => {
          setPdfTitle("");
          setPdfFile("");
          setPdfDescription("");
          getUserManualsData();
        });
      });
  };

  const deleteUserManual = async (userManualID) => {
    swal({
      title: "آیا از حذف فایل اطمینان دارید؟",
      buttons: ["نه", "آره"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);
        await apiRequests(`/user-manuals/delete/${userManualID}`).then(
          (res) => {
            setIsLoader(false);

            swal({
              title: "اطلاعات با موفقیت حذف شد.",
              icon: "success",
              buttons: "حله",
            }).then((result) => {
              getUserManualsData();
              setIsValid(false);
            });
          }
        );
      }
    });
  };

  const editeUserManual = (userManual) => {
    setIsUpdate(true);
    setPdfID(userManual.id);
    setPdfTitle(userManual.title);
    setPdfFile(userManual.file);
    setPdfDescription(userManual.description);
    setIsValid(false);
  };

  const updateUserManualsDetails = async () => {
    let updateUserManual = {
      title: pdfTitle,
      description: pdfDescription,
      file: pdfFile,
    };

    await apiRequests
      .post(`/user-manuals/update/${pdfID}`, updateUserManual)
      .then((res) => {
        swal({
          title: "تغییرات با موفقیت ذخیره شد.",
          icon: "success",
          buttons: "حله",
        }).then((result) => {
          getUserManualsData();
        });
      });
  };

  const uploader = async (event) => {
    setIsLoader(true);
    let fileUrl = "";

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 15|2TuyZks9sy04RtnveX53BQpf5PFtrYxEqaGO7hfef18911c4"
    );

    let formdata = new FormData();
    formdata.append("image", event.target.files[0]);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      "https://meta-detector.com/v1/api/file/upload-file",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        fileUrl = result.data.url;

        setIsLoader(false);
      })
      .catch((error) => console.log("error", error));

    switch (event.target.name) {
      case "pdf-file":
        setPdfFile(fileUrl);
        break;
    }
  };

  useEffect(() => {
    getUserManualsData();
  }, []);

  useEffect(() => {
    if (pdfTitle && pdfFile && pdfDescription.length) {
      if (!isUpdate) {
        setIsValid(true);
      }
    }
  }, [pdfTitle, pdfFile, pdfDescription]);

  return (
    <div dir="rtl" className="user-manual">
      <Loader loaderStyle={isLoader ? "flex" : "none"} />

      <h1 className="fw-bold">راهنمای کاربر</h1>

      {/* User Manusl */}
      <div className="row shadow rounded-4 my-4 py-3 row-gap-2">
        <div>
          <div className="col-md-6">
            <h4 className="my-3 feature-title">عنوان</h4>
            <input
              name="pdf-title"
              type="text"
              className="form-control"
              id="pdf-title"
              placeholder="عنوان فایل"
              value={pdfTitle}
              onChange={(event) => setPdfTitle(event.target.value)}
            />
          </div>

          <div className="col-6 imags-uploader">
            <h4 className="my-3 feature-title">آپلود فایل</h4>
            <label for="file7" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file7"
                type="file"
                name="pdf-file"
                onChange={uploader}
              />
            </label>
          </div>

          <div className="col-12">
            <h4 className="my-3 feature-title">توضیحات</h4>

            <textarea
              value={pdfDescription}
              onChange={(event) => setPdfDescription(event.target.value)}
            ></textarea>
          </div>

          {isValid ? (
            <button
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
              onClick={submitUserManualsDetails}
            >
              ایجاد
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
            >
              ایجاد
            </button>
          )}

          {isUpdate ? (
            <button
              className="btn btn-primary btn-color my-4"
              type="submit"
              onClick={updateUserManualsDetails}
            >
              بروزرسانی
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4"
              type="submit"
            >
              بروزرسانی
            </button>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>عنوان</th>
              <th>توضیحات</th>
              <th className="w-50px ">اکشن</th>
            </tr>
          </thead>
          <tbody>
            {userManualsData.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">{index + 1}</th>
                <td>{item.title}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                ></td>
                <td className="d-block text-center">
                  <button
                    className="btn btn-danger mb-2"
                    onClick={() => deleteUserManual(item.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>

                  <button
                    class="btn btn-success"
                    onClick={() => editeUserManual(item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
