import axios from "axios";
import { Navigate } from "react-router-dom";

const apiRequests = axios.create({
    baseURL: "https://meta-detector.com/v1/api",
    headers: {
        "Content-Type": `application/json`,
        "Access-Control-Allow-Origin": "*",
        "Accept": `application/json`,
    },
});

apiRequests.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`

        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

apiRequests.interceptors.response.use(
    (response) => {
        console.log(response);
        return response;
    },
    (err) => {
        console.log(err);
        if (err.response.status === 401) {
            window.location.href = "/p-admin/login";
        }
        return Promise.reject(err);
    }
);

export default apiRequests