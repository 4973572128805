import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Loader from "../../components/loader/Loader";
import apiRequests from "../../services/config";

function ContactUs() {
  const [isLoader, setIsLoader] = useState(true);
  const [contactUs, setContactUs] = useState("");

  useEffect(() => {
    apiRequests("/get-setting").then((res) => {
      setContactUs(res.data.data.contact_us);
      setIsLoader(false);
    });
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }
  return (
    <>
      <Header />
      <main id="content" style={{ paddingBottom: "0" }}>
        <div className="container-fluid">
          <Breadcrumb links={[{ id: 3, title: "Where To Buy?" }]} />
          <div
            className="wrapper"
            dangerouslySetInnerHTML={{ __html: contactUs }}
          ></div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ContactUs;
