import React, { useEffect, useState } from "react";
import apiRequests from "../../../services/config";

import "./Dashboard.css";

export default function Dashboard() {
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    await apiRequests("https://meta-detector.com/v1/api/products").then(
      (result) => setProducts(result.data.data)
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div dir="rtl">
      <h1 className="mt-4 fw-bold">داشبورد</h1>
      <div className="row mt-3">
        <table className="table table-dark dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>عنوان</th>
              <th>تصویر</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id}>
                {console.log(product)}
                <th scope="row">{index + 1}</th>
                <td>{product.title}</td>
                <td>
                  <div className="product-cover">
                    <img src={`./${product.image.url}`} alt="" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
