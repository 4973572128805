import { useEffect, useState } from "react";
import apiRequests from "../../../services/config";
import "./Login.css";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const loginAdmin = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);

    await apiRequests.post("/login", formData).then((result) => {
      if (result.data.access_token) {
        localStorage.setItem("token", result.data.token);
        
        navigate("/p-admin");
      }
    });
  };

  return (
    <>
      <div className="form-container">
        <p className="title">ورود</p>
        <form className="form">
          <div className="input-group">
            <label for="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              placeholder=""
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className="input-group">
            <label for="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder=""
              onChange={(event) => setPassword(event.target.value)}
            />
            <div className="forgot">
              <a rel="noopener noreferrer" href="#">
                Forgot Password ?
              </a>
            </div>
          </div>
          <button className="sign" onClick={loginAdmin}>
            ورود
          </button>
        </form>
      </div>
    </>
  );
};
