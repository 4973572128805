import React, { useEffect, useState } from "react";

import "./Test.css";
import { Link } from "react-router-dom";

export default function Test({ slideTow, slideThree }) {
  const [titleSlideLeft, setTitleSlideLeft] = useState("");
  const [descriptionSlideLeft, setDescriptionSlideLeft] = useState("");
  const [imgSlideLeft, setImgSlideLeft] = useState("");
  const [slideLeft, setSlideLeft] = useState({});
  const [titleSlideRight, setTitleSlideRight] = useState("");
  const [descriptionSlideRight, setDescriptionSlideRight] = useState("");
  const [imgSlideRight, setImgSlideRight] = useState("");
  const [slideRight, setSlideRight] = useState({});

  useEffect(() => {
    setSlideLeft({
      title: slideTow.title,
      description: slideTow.description,
      image: slideTow.image1,
      link: slideTow.link,
    });
    setSlideRight({
      title: slideThree.title,
      description: slideThree.description,
      image: slideThree.image1,
      link: slideThree.link,
    });
    setTitleSlideLeft(slideTow?.title);
    setDescriptionSlideLeft(slideTow?.description);
    setImgSlideLeft(slideTow?.image1);
    setTitleSlideRight(slideThree?.title);
    setDescriptionSlideRight(slideThree?.description);
    setImgSlideRight(slideThree?.image1);
  }, []);

  return (
    <>
      <div className="wrapper-split">
        <div className="row bloc bloc-deus" style={{ visibility: "visible" }}>
          <div className="col-md-6">
            <div className="w-75 img-block">
              <img
                src={`${window.location.origin}${slideLeft.image}`}
                alt=""
                className="img-fluid img-deus img-reveal"
                style={{ display: "inline" }}
              />
            </div>
          </div>

          <div className="col-md-6 caption ">
            <h3>
              <span>{slideLeft.title}</span>
            </h3>
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: slideLeft.description,
              }}
            ></div>
            {typeof slideLeft.link === "string" ? (
              <Link to={slideLeft.link}>By now</Link>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row bloc bloc-orx" style={{ visibility: "visible" }}>
          <div className="col-md-6 caption text-white my-4 text-start">
            <h3>
              <span>{slideRight.title}</span>
            </h3>
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: slideRight.description,
              }}
            ></div>
            {typeof slideRight.link === "string" ? (
              <Link to={slideRight.link}>By now</Link>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-6">
            <div className="w-75 img-block">
              <img
                src={`${window.location.origin}${slideRight.image}`}
                alt=""
                className="img-fluid img-orx img-reveal"
                style={{ display: "inline" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
