import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Editor from "../../../components/adminPanel/form/Editor";
import apiRequests from "../../../services/config";

import "./Product.css";
import swal from "sweetalert";
import Loader from "../../../components/loader/Loader";

export default function Product() {
  const [products, setProducts] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [productID, setProductID] = useState(0);
  const [productTitle, setProductTitle] = useState("");
  const [groupID, setGroupID] = useState(-1);
  const [imageCover, setImageCover] = useState([]);
  const [description, setDescription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [productImagesA, setProductImagesA] = useState([]);
  const [imageSliderUrl, setImageSliderUrl] = useState([]);
  const [productRowImages, setProductRowImages] = useState([]);
  const [rowImageUrl, setRowImageUrl] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const getProducts = async () => {
    await apiRequests("https://meta-detector.com/v1/api/products").then(
      (result) => setProducts(result.data.data)
    );
  };

  const getGroupsList = async () => {
    await apiRequests
      .get("/groups/list")
      .then((result) => setGroupsList(result.data.data));
  };

  const createProduct = async () => {
    if (
      productTitle &&
      groupID !== "-1" &&
      imageCover.id &&
      description.length &&
      specifications.length &&
      productImagesA.length &&
      productRowImages.length
    ) {
      setIsLoader(true);
      let newProductValue = {
        title: productTitle,
        group_id: groupID,
        image: imageCover.id,
        description: description,
        specifications: specifications,
        product_images: productImagesA,
        product_row_images: productRowImages,
      };

      console.log(newProductValue);

      await apiRequests
        .post("/products/create", newProductValue)
        .then((result) => {
          setIsLoader(false);
          swal({
            title: "اطلاعات با موفقیت ثبت شد.",
            icon: "success",
            buttons: "حله",
          });
          getProducts();
          setProductTitle("");
          setGroupID(0);
          setImageCover(0);
          setDescription("");
          setSpecifications("");
          setProductImagesA([]);
          setProductRowImages([]);
          setImageSliderUrl([]);
          setRowImageUrl([]);
          setIsValid(false);
        });
    } else {
      console.log("Check");
    }
  };

  const deleteProduct = async (productID) => {
    swal({
      title: "آیا از حذف منو اطمینان دارید؟`",
      icon: "warning",
      buttons: ["نه", "آره"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);
        await apiRequests(`/products/delete/${productID}`).then((result) => {
          setIsLoader(false);
          swal({
            title: "اطلاعات با موفقیت حذف شد.",
            icon: "success",
            buttons: "حله",
          });
          getProducts();
        });
      }
    });
  };

  const editeProduct = (product) => {
    setIsUpdate(true);

    console.log(isUpdate);
    console.log(product);

    setProductTitle(product.title);
    setImageCover({ id: product.image.id, url: product.image.url });
    setGroupID(product.group_id);
    setDescription(product.description);
    setSpecifications(product.specifications);
    setProductID(product.id);
    // setIsValid(false);
  };

  const updateProductNewValue = async () => {
    let updateProductValue = {
      title: productTitle,
      group_id: groupID,
      image: imageCover.id,
      description: description,
      specifications: specifications,
      product_images: productImagesA,
      // product_images: productImagesB,
      product_row_images: productRowImages,
    };

    await apiRequests
      .post(`/products/update/${productID}`, updateProductValue)
      .then((result) => {
        setIsLoader(true);
        swal({
          title: "اطلاعات با موفقیت بروزرسانی شد.",
          icon: "success",
          buttons: "حله",
        });
        setIsLoader(false);
        getProducts();

        setProductTitle("");
        setGroupID(0);
        setImageCover(0);
        setDescription("");
        setSpecifications("");
        setProductImagesA([]);
        setProductRowImages([]);

        setIsUpdate(false);
        setIsValid(false);
      });
  };

  useEffect(() => {
    getProducts();
    getGroupsList();
  }, []);

  useEffect(() => {
    if (
      productTitle &&
      groupID !== -1 &&
      imageCover.id &&
      description.length &&
      specifications.length &&
      productImagesA.length &&
      productRowImages.length
    ) {
      if (!isUpdate) {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
      // setIsUpdate(false);
    }
  }, [
    productTitle,
    groupID,
    imageCover,
    description,
    specifications,
    productImagesA,
    productRowImages,
  ]);

  //
  const uploader = async (event) => {
    setIsLoader(true);
    let fileID = null;
    let fileUrl = "";

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 15|2TuyZks9sy04RtnveX53BQpf5PFtrYxEqaGO7hfef18911c4"
    );

    let formdata = new FormData();
    formdata.append("image", event.target.files[0]);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      "https://meta-detector.com/v1/api/file/upload-file",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        fileID = result.data.id;
        fileUrl = result.data.url;
        console.log(fileUrl);
        setIsLoader(false);
      })
      .catch((error) => console.log("error", error));

    switch (event.target.name) {
      case "image-cover":
        setImageCover({ id: fileID, url: fileUrl });
        break;
      case "image-slider":
        setProductImagesA((preveState) => [...preveState, fileID]);
        setImageSliderUrl((preveState) => [
          ...preveState,
          { id: fileID, url: fileUrl },
        ]);
        break;
      case "image-row":
        setProductRowImages((preveState) => [...preveState, fileID]);
        setRowImageUrl((preveState) => [
          ...preveState,
          { id: fileID, url: fileUrl },
        ]);
        break;
    }
  };
  //

  const removeImageSlider = (type, imageID) => {
    let filteredImageSlider = [];
    switch (type) {
      case "slider":
        filteredImageSlider = imageSliderUrl.filter(
          (item) => item.id !== imageID
        );
        setImageSliderUrl(filteredImageSlider);
        break;
      case "row":
        filteredImageSlider = rowImageUrl.filter((item) => item.id !== imageID);
        setRowImageUrl(filteredImageSlider);
        break;
    }
  };

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div dir="rtl">
      {/* <Loader loaderStyle={isLoader ? "flex" : "none"} /> */}

      <h1 className="mt-4 fw-bold">محصولات</h1>
      <div className="row justify-content-center shadow rounded-4 my-4 py-3">
        <div className="col-md-5 mt-5">
          <div>
            <form className="p-2">
              <div className="row">
                <div className="mb-4">
                  <h4 className="my-3 feature-title">عنوان</h4>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="Title"
                    required=""
                    value={productTitle}
                    onChange={(event) => setProductTitle(event.target.value)}
                  />
                </div>

                <div className="form-group">
                  <h4 className="my-3 feature-title">انتخاب گروه</h4>
                  <select
                    name="select"
                    id="exampleSelect"
                    className="form-control"
                    onChange={(event) => setGroupID(event.target.value)}
                    value={groupID}
                  >
                    <option value={-1}>... انتخاب کنید</option>
                    {groupsList.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Uploder */}
        <div>
          <h4 className="mt-3 m-0 feature-title">
            <span className="feature-subtitle">
              ( سایز تصاویر در این صفحه باید <span>620*780 پیکسل</span> باشد )
            </span>
            تصویر محصول
          </h4>
          <div className="imags-uploader">
            <label for="file1" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file1"
                type="file"
                name="image-cover"
                onChange={uploader}
              />
            </label>
            <div className="image-preview">
              <img src={`../../..${imageCover.url}`} alt="" />
              <i
                classNam={`${imageCover.url && "bi bi-trash"}`}
                onClick={() => setImageCover([])}
              >
                {imageCover.url && "X"}
              </i>
            </div>
          </div>

          <div>
            <h4 className="my-3 feature-title">تصاویر اسلایدر</h4>
            <div className="imags-uploader">
              <label for="file2" className="custum-file-upload">
                <div className="icon">
                  <svg
                    viewBox="0 0 24 24"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fillRule="evenodd"
                        clip-rule="evenodd"
                        d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                        fill=""
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
                <input
                  id="file2"
                  type="file"
                  name="image-slider"
                  onChange={uploader}
                />
              </label>
              {imageSliderUrl?.map((image) => (
                <div key={image.id} className="image-preview">
                  <img src={image.url} alt="" />
                  <i
                    classNam="bi bi-trash"
                    onClick={() => removeImageSlider("slider", image.id)}
                  >
                    X
                  </i>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4 className="my-3 feature-title">تصاویر صفحه</h4>
            <div className="imags-uploader">
              <label for="file3" className="custum-file-upload">
                <div className="icon">
                  <svg
                    viewBox="0 0 24 24"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fillRule="evenodd"
                        clip-rule="evenodd"
                        d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                        fill=""
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
                <input
                  id="file3"
                  type="file"
                  name="image-row"
                  onChange={uploader}
                />
              </label>
              {rowImageUrl.map((image) => (
                <div key={image.id} className="image-preview">
                  <img src={image.url} alt="" />
                  <i
                    classNam="bi bi-trash"
                    onClick={() => removeImageSlider("row", image.id)}
                  >
                    X
                  </i>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-12 my-5">
          <h3>توضیحات</h3>
          <Editor value={description} setValue={setDescription} />
        </div>

        <div className="col-12">
          <h3>مشخصات فنی</h3>
          <Editor value={specifications} setValue={setSpecifications} />

          {isValid ? (
            <button
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
              onClick={createProduct}
            >
              ایجاد
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
              onClick={createProduct}
            >
              ایجاد
            </button>
          )}

          {isUpdate ? (
            <button
              className="btn btn-primary btn-color my-4"
              type="submit"
              onClick={updateProductNewValue}
            >
              بروزرسانی
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4"
              type="submit"
            >
              بروزرسانی
            </button>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>عنوان</th>
              <th className="w-50px ">اکشن</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id}>
                <th scope="row">{index + 1}</th>
                <td>{product.title}</td>
                <td className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProduct(product.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>

                  <button
                    class="btn btn-success"
                    onClick={() => editeProduct(product)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
