import React, { useEffect } from "react";

import "./Terrain.css";

export default function Terrain({ appDatas }) {
  return (
    <div className="container">
      <div className="wrapper-go-terrain">
        <div className="row">
          <div className="col-lg-6">
            <div className="caption-img">
              <img
                src={`${window.location.origin}${appDatas.appSiliders[0]?.url}`}
                alt=""
                className="img-fluid img-top img-terrain"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="caption">
              <h3>
                <span> {appDatas.appTitle} </span>{" "}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: appDatas.appDescription.slice(0, 300),
                }}
              ></p>
              <div className="action">
                <a href="/download-app" className="see-all">
                  <span>Download</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
