import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";

import "./CheckSerial.css";

function CheckSerial() {
  const [isLoader, setIsLoader] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [serialData, setSerialData] = useState([]);

  const checkSerials = async (event) => {
    event.preventDefault();
    setIsLoader(true);

    fetch(
      `https://meta-detector.com/v1/api/serial-devices?serial=${serialNumber}`
    )
      .then((res) => res.json())
      .then((result) => {
        setSerialData(result.data);

        setIsLoader(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <div className="container">
        <div>
          <Loader loaderStyle={isLoader ? "flex" : "none"} />
          <div className="row justify-content-evenly mt-main">
            <div className="shadow rounded-4">
              <div>
                <form className="p-2">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" for="validationCustom01">
                        Serials
                      </label>
                      <input
                        name="serial"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        onChange={(event) =>
                          setSerialNumber(event.target.value)
                        }
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-color my-4 ms-3"
                    type="submit"
                    onClick={checkSerials}
                  >
                    Search
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <table className="table table-dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Serial</th>
                  <th>imei</th>
                  <th>details</th>
                </tr>
              </thead>
              <tbody>
                {serialData.length
                  ? serialData.map((serial, index) => (
                      <tr key={serial.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{serial.serial}</td>
                        <td>{serial.imei}</td>
                        <td>{serial.client_details}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CheckSerial;
