import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "./Sidebar.css";

export default function Sidebar() {
  const [isShow, setIsShow] = useState(false);
  const param = useParams("/");

  const sidebarToggleBtn = () => {
    if (isShow) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  };

  useEffect(() => {
    if (param) {
      setIsShow(false);
    }
  }, [param]);
  return (
    <>
      <button
        className="btn btn-primary sidebar-btn"
        type="button"
        // data-bs-toggle="offcanvas"
        onClick={() => sidebarToggleBtn()}
        data-bs-target="#offcanvasScrolling"
        aria-controls="offcanvasScrolling"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </button>

      <div
        className={`offcanvas offcanvas-end ${isShow ? "show" : ""}`}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            onClick={() => setIsShow(false)}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div id="layoutSidenav_nav">
            <nav
              className="sb-sidenav accordion sb-sidenav-dark"
              id="sidenavAccordion"
            >
              <div className="sb-sidenav-menu">
                <div className="nav">
                  <Link className="nav-link" to="">
                    داشبورد
                  </Link>
                  <Link className="nav-link" to="group">
                    گروه
                  </Link>
                  <Link className="nav-link" to="product">
                    محصولات
                  </Link>
                  <Link className="nav-link" to="serials">
                    سریال
                  </Link>
                  <Link className="nav-link" to="faq-group">
                    FAQ گروه
                  </Link>
                  <Link className="nav-link" to="faq">
                    FAQ
                  </Link>
                  <Link className="nav-link" to="user-manual">
                    راهنما
                  </Link>
                  <Link className="nav-link" to="settings">
                    تنظیمات
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
